const madLiberationStyles = {
  ".red": { color: 'red' },
  ".italic": { fontStyle: 'italic' },
  ".blue": { color: 'blue' },
  ".blueItalic": { color: 'blue', fontStyle: 'italic' },
  ".lightGrayBackround": { backgroundColor: 'lightgray' },
  ".paper": {
    display: 'inline-block',
    padding: '3px',
    margin: '2px'
  },
  ".whitePaper": {
    backgroundColor: 'white',
    color: 'black',
    padding: '20px'
  },
  ".input": {
    display: 'none'
  },
  ".paperContainer": {
    height: 1356,
    backgroundImage: `url(${'../background-red-sea.jpg'})`
  }
};
export { madLiberationStyles };
